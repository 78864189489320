<template>
  <v-container fluid class="pageContainer" :class="loading ? 'shield-container' : ''">
    <div v-if="loading" class="shield-container">
      <v-progress-circular
        indeterminate
        size="25"
        width="3"
        class="px-6"
        color="primary"
      />
    </div>
    <div
      v-else
      id="shield-container"
      class="shield-container"
    >
      <inline-svg
        :src="`${shield.image}?x-request=xhr`"
        id="shield"
        class="shield-image"
      />
    </div>
  </v-container>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    InlineSvg
  },
  data () {
    return {
      loading: true,
      shield: {},
      colors: []
    }
  },
  mounted () {
    window.addEventListener('flutterInAppWebViewPlatformReady', (event) => {
      this.getDataFromApp()
    })
  },
  methods: {
    updateElementColorValue (data) {
      var width = document.getElementById('shield-container').offsetWidth
      var height = document.getElementById('shield-container').offsetHeight

      var svg = document.getElementById('shield')

      svg.setAttribute('width', width)
      svg.setAttribute('height', height)
      svg.getElementById(this.shield.elements[data.index].id).style.fill = data.hex
    },
    getDataFromApp () {
      var self = this
      window.flutter_inappwebview
        .callHandler('getSelectedValues', '')
        .then(function (result) {
          self.shield = result.pattern
          self.colors = result.colors
          self.loading = false
          setTimeout(() => {
            for (let elementIndex = 0; elementIndex < self.shield.elements.length; elementIndex++) {
              self.updateElementColorValue({
                index: elementIndex,
                hex: self.colors[elementIndex]
              })
            }
          }, 500)
        })
    },
    imageErrorHandler (index) {
      this.shields[index].image = imageOffIcon
    }
  }
}
</script>

<style scoped>
.pageContainer {
  background: #F8F8F8;
  height: 100vh;
  padding: 0;
}

.shield-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
}

.shield-image {
  /* width: 100%;
  height: 50%; */
}
</style>
